var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
/*
THIS INPUT BLOCK IS DEPRECATED AND SHOULD BE REMOVED SOON
IF ANY CHANGES ARE NEEDED, PLEASE UPDATE MULTIPLE FILE UPLOAD BLOCK
*/
import './style.css';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { useEffect, useRef, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import { useTranslation } from 'react-i18next';
import uploadImg from '@/assets/img/icons/upload.svg';
import { useResolveValue } from '@/hooks';
import { AWSService } from '@/services';
import { ValuesType } from '@/types';
import { formatFileSize, getInitialFiles, getInputBlockAnswer } from '@/utils';
import { InputBlockLayout } from '../fragments/InputBlockLayout';
registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);
export const FileUploadSingle = ({ block: { answer, cancel_label, completed_label, error_format_label, error_processing_label, formats, id, key, layout, max_files = null, max_size, remove_label, required, uploading_label, }, defaultValue, onChange, options, }) => {
    const { t } = useTranslation();
    useEffect(() => {
        var _a;
        if (!defaultValue) {
            (_a = uploadRef.current) === null || _a === void 0 ? void 0 : _a.removeFiles();
        }
    }, [defaultValue]);
    const uploadRef = useRef(null);
    const [files, setFiles] = useState([]);
    const [resolver] = useResolveValue();
    const resolved = {
        label: resolver.resolveString(answer.label),
        labelButtonRemoveItem: resolver.resolveString(remove_label),
        labelFileProcessing: resolver.resolveString(uploading_label),
        labelFileProcessingComplete: resolver.resolveString(completed_label),
        labelFileProcessingError: t('common:message.error.default'),
        labelFileTypeNotAllowed: resolver.resolveString(error_format_label),
        labelMaxFileSizeExceeded: resolver.resolveString(error_processing_label),
        labelTapToCancel: resolver.resolveString(cancel_label),
        required: resolver.resolveBoolean(required),
    };
    const labels = Object.assign(Object.assign({}, resolved), { labelIdle: `<img src='${uploadImg}'/> ${resolved.label}` });
    const handleChange = (file) => {
        const answerValue = Object.assign(Object.assign({}, answer), { label: resolved.label, value: file
                ? {
                    format: file.fileType,
                    id: file.serverId,
                    name: file.filename,
                    size: file.fileSize,
                }
                : null });
        const newAnswer = getInputBlockAnswer(ValuesType.FILE, answerValue, key);
        onChange(newAnswer);
    };
    const handleProcessFile = (error, file) => {
        if (!error && file.origin === 1)
            handleChange(file);
    };
    const handleRemoveFile = () => {
        setTimeout(() => handleChange(), 200);
    };
    const handleServerProcess = (_fieldName, file, _metadata, load, error, progress, abort) => __awaiter(void 0, void 0, void 0, function* () {
        yield AWSService.createS3File({
            awsFileIds: options,
            filepond: { abort, error, file, load, progress },
        });
    });
    const handleInit = () => {
        const fileList = getInitialFiles(defaultValue);
        setFiles(fileList);
    };
    return (_jsx(InputBlockLayout, Object.assign({ layout: layout }, { children: _jsx(FilePond, Object.assign({ acceptedFileTypes: formats, allowMultiple: max_files > 1 || !max_files, credits: false, "data-test": id, files: files, maxFileSize: formatFileSize(max_size), maxFiles: max_files, name: key, oninit: handleInit, onprocessfile: handleProcessFile, onremovefile: handleRemoveFile, onupdatefiles: setFiles, ref: uploadRef, server: {
                revert: null,
                process: handleServerProcess,
            } }, labels)) })));
};
