var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { MarkdownBase } from '@/components';
export const InputControl = (_a) => {
    var { children, error, isInvalid, label } = _a, rest = __rest(_a, ["children", "error", "isInvalid", "label"]);
    return (_jsxs(FormControl, Object.assign({ className: isInvalid && 'is-invalid', isInvalid: isInvalid }, rest, { sx: {
            '&.is-invalid .dropdown-select > div': {
                borderColor: 'red.100',
            },
        } }, { children: [label && (_jsx(FormLabel, Object.assign({ color: "darkBrandBlue.40" }, { children: _jsx(MarkdownBase, { markdown: String(label) }) }))), children, isInvalid && error && (_jsx(FormErrorMessage, { children: _jsx(MarkdownBase, { markdown: error }) }))] })));
};
