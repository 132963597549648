var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Stack } from '@chakra-ui/react';
import { useConfig } from '@/store';
export const BlockStyle = (_a) => {
    var _b;
    var { children, style_id, styles = {} } = _a, rest = __rest(_a, ["children", "style_id", "styles"]);
    const { getStyleConfig } = useConfig();
    const { on_primary = '', primary = (_b = rest.bg) !== null && _b !== void 0 ? _b : '' } = getStyleConfig(style_id) || styles;
    let filter = '';
    if (on_primary === '#FFFFFF' || on_primary === '#000000') {
        filter = on_primary === '#FFFFFF' ? 'brightness(0%) invert(100%)' : 'brightness(0%)';
    }
    return (_jsx(Stack, Object.assign({}, rest, { bg: primary, borderRadius: "8px", color: on_primary, css: { 'img:not(svg)': { filter } } }, { children: children })));
};
