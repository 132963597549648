import { jsx as _jsx } from "react/jsx-runtime";
import { useRadioGroup } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { BlockErrors, InputGroupBase, RadioItem } from '@/components';
import { useResolveValue } from '@/hooks';
import { InputBlockType, ValuesType } from '@/types';
import { filterBlockAnswers, getInputBlockAnswer } from '@/utils';
import { InputBlockLayout } from '../fragments/InputBlockLayout';
export const RadioGroup = ({ block: { answer_filters = [], answers = [], id, images = [], key, layout, type }, defaultValue = '', errors = [], onChange, }) => {
    useEffect(() => {
        setValue(JSON.stringify(defaultValue));
    }, [defaultValue]);
    const { getRadioProps, getRootProps, setValue } = useRadioGroup({
        defaultValue: JSON.stringify(defaultValue),
    });
    const [resolver] = useResolveValue();
    const resolved = useMemo(() => {
        const answerKeys = resolver.resolveAnswerFilter(answer_filters);
        const filters = filterBlockAnswers(answers, answerKeys, defaultValue);
        const answersList = filters.answersFiltered.map((a, order) => {
            return Object.assign(Object.assign({}, a), { label: resolver.resolveString(a.label), order });
        });
        return {
            answersList,
        };
    }, []);
    const handleChange = (answer) => {
        let valueType;
        switch (type) {
            case InputBlockType.INPUT_INTEGER_RADIO_GROUP:
                valueType = ValuesType.INTEGER;
                break;
            case InputBlockType.INPUT_RANGE_RADIO_GROUP:
                valueType = ValuesType.RANGE;
                break;
            case InputBlockType.INPUT_STRING_RADIO_GROUP:
                valueType = ValuesType.STRING;
                break;
        }
        const newAnswer = getInputBlockAnswer(valueType, answer, key);
        onChange(newAnswer, { order: answer.order });
    };
    return (_jsx(BlockErrors, Object.assign({ errors: errors }, { children: _jsx(InputBlockLayout, Object.assign({ layout: layout }, { children: _jsx(InputGroupBase, Object.assign({ "data-test": id }, getRootProps(), { children: resolved.answersList.map((item) => {
                    const value = JSON.stringify(item.value);
                    return (_jsx(RadioItem, Object.assign({ answer: item, answerChange: handleChange, images: images }, getRadioProps({ value })), item.id));
                }) })) })) })));
};
