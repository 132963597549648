import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import { configReducer, initialConfigState, useAuth } from '@/store';
import { APP_CONFIG } from '@/types';
import { getArrListByKey, getLocalStorage, getObjListByKey, mergeArrays } from '@/utils';
const ConfigContext = createContext({
    state: initialConfigState,
    dispatch: () => undefined,
    getComponentConfig: () => undefined,
    getDestinationConfig: () => undefined,
    getImageConfig: () => undefined,
    getNavigationConfig: () => undefined,
    getNavigationDestinationConfig: () => undefined,
    getStyleConfig: () => undefined,
});
export const useConfig = () => useContext(ConfigContext);
export const ConfigProvider = ({ children }) => {
    const [state, dispatch] = useReducer(configReducer, initialConfigState);
    const { api_url } = getLocalStorage();
    const { state: { currentUserPhase }, } = useAuth();
    const configMap = useMemo(() => {
        const { components, images, navigation_destinations, styles } = state.appConfig;
        const componentsMap = getArrListByKey(components, 'type');
        const navDestinationsMap = getObjListByKey(navigation_destinations, 'uuid');
        const stylesMap = getObjListByKey(styles, 'uuid');
        const imagesMap = Object.fromEntries(images.map((x) => [x.uuid, api_url + x.url]));
        return {
            componentsMap,
            imagesMap,
            navDestinationsMap,
            stylesMap,
        };
    }, [state.appConfig]);
    const getComponentConfig = useCallback((type, phaseId = currentUserPhase) => {
        const list = configMap.componentsMap[type];
        if (list === null || list === void 0 ? void 0 : list.length) {
            const component = list.find(({ data }) => { var _a; return 'phases' in data && ((_a = data === null || data === void 0 ? void 0 : data.phases) === null || _a === void 0 ? void 0 : _a.includes(phaseId)); }) ||
                list[list.length - 1];
            return component === null || component === void 0 ? void 0 : component.data;
        }
    }, [configMap]);
    const getDestinationConfig = useCallback((id) => {
        return configMap.navDestinationsMap[id];
    }, [configMap]);
    const getNavigationConfig = useCallback((id) => {
        const nav = getComponentConfig(APP_CONFIG.COMPONENTS.ConfigComponentType.NAVIGATION);
        return mergeArrays(nav === null || nav === void 0 ? void 0 : nav.primary_navigation, nav === null || nav === void 0 ? void 0 : nav.secondary_navigation).find((x) => x.uuid === id);
    }, [configMap]);
    const getNavigationDestinationConfig = useCallback((id) => configMap.navDestinationsMap[id], [state.appConfig]);
    const getImageConfig = useCallback((id) => {
        return configMap.imagesMap[id];
    }, [configMap]);
    const getStyleConfig = useCallback((id) => {
        return configMap.stylesMap[id];
    }, [configMap]);
    const value = useMemo(() => ({
        configMap,
        dispatch,
        getComponentConfig,
        getDestinationConfig,
        getImageConfig,
        getNavigationConfig,
        getNavigationDestinationConfig,
        getStyleConfig,
        state,
    }), [state]);
    return _jsx(ConfigContext.Provider, Object.assign({ value: value }, { children: children }));
};
