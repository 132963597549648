import { jsx as _jsx } from "react/jsx-runtime";
import { useCheckboxGroup } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { BlockErrors, CheckboxItem, InputGroupBase } from '@/components';
import { useResolveValue } from '@/hooks';
import { filterBlockAnswers, getAnswerValue, getInputBlockAnswer } from '@/utils';
import { InputBlockLayout } from '../fragments/InputBlockLayout';
export const MultiOptionSelect = ({ block: { answer_filters = [], id, images = [], key, layout, max, typed_answers }, defaultValue, errors = [], onChange, }) => {
    const getMappedValue = defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.map((x) => JSON.stringify(getAnswerValue(x)));
    useEffect(() => {
        setValue(getMappedValue);
    }, [defaultValue]);
    const { getCheckboxProps, setValue, value: checkedList, } = useCheckboxGroup({
        defaultValue: getMappedValue,
    });
    const [resolver] = useResolveValue();
    const resolved = useMemo(() => {
        const answerKeys = resolver.resolveAnswerFilter(answer_filters);
        const filters = filterBlockAnswers(typed_answers.answers, answerKeys, defaultValue);
        const answersList = filters.answersFiltered.map((a, order) => {
            return Object.assign(Object.assign({}, a), { label: resolver.resolveString(a.label), order });
        });
        return {
            answersList,
        };
    }, []);
    const isDisabled = (val) => !checkedList.includes(val) && checkedList.length >= max;
    const handleChange = (answer) => {
        const newAnswer = getInputBlockAnswer(typed_answers.type, answer, key);
        onChange(newAnswer, {
            multipleAnswer: true,
            order: answer.order,
        });
    };
    return (_jsx(BlockErrors, Object.assign({ errors: errors }, { children: _jsx(InputBlockLayout, Object.assign({ layout: layout }, { children: _jsx(InputGroupBase, Object.assign({ "data-test": id }, { children: resolved.answersList.map((item) => {
                    const value = JSON.stringify(item.value);
                    return (_jsx(CheckboxItem, Object.assign({ answer: item, answerChange: handleChange, images: images, isDisabled: isDisabled(value) }, getCheckboxProps({ value })), item.id));
                }) })) })) })));
};
