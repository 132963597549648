export default {
    amber80: '#ff9f00',
    fadedWhite: 'rgba(255, 255, 255, 0.4)',
    green60: '#37BE96',
    green80: '#00A876',
    grey20: '#D8D8D8',
    grey60: '#ECEEF0',
    grey80: '#464646',
    grey90: '#333333',
    red100: '#ff0000',
    red80: '#FE5558',
    white100: '#FFF',
};
