import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputControl } from '@/components';
import { useDebounce, useResolveValue } from '@/hooks';
import { KeyboardType, ValuesType, keyboardTypeMap } from '@/types';
import { errorDebounceMillis, getInputBlockAnswer } from '@/utils';
import { HStack, Text, Textarea } from '@chakra-ui/react';
import GraphemeSplitter from 'grapheme-splitter';
import { useEffect, useRef } from 'react';
import { InputBlockLayout } from '../fragments/InputBlockLayout';
const splitter = new GraphemeSplitter();
export const FreeTextField = ({ block: { answer, id, key, keyboard_type = KeyboardType.TEXT, layout, max_length = null, placeholder_text, required, }, defaultValue = '', errors = [], onChange, }) => {
    useEffect(() => {
        inputRef.current.value = defaultValue;
    }, [defaultValue]);
    const inputRef = useRef();
    const [resolver] = useResolveValue();
    const resolved = {
        label: resolver.resolveString(answer.label),
        placeholder: resolver.resolveString(placeholder_text),
        required: resolver.resolveBoolean(required),
    };
    const debounceError = useDebounce(errors, errorDebounceMillis);
    const totalChar = splitter.countGraphemes(defaultValue) || 0;
    const isInvalid = (max_length && totalChar > max_length) || debounceError.length > 0;
    const minHeight = '48px';
    const handleChange = (e) => {
        inputRef.current.style.height = minHeight;
        inputRef.current.style.height = `${e.target.scrollHeight}px`;
        const { value } = e.target;
        const answerValue = Object.assign(Object.assign({}, answer), { label: resolved.label, value: value === '' ? null : value });
        const newAnswer = getInputBlockAnswer(ValuesType.STRING, answerValue, key);
        onChange(newAnswer);
    };
    useEffect(() => {
        inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }, []);
    return (_jsx(InputBlockLayout, Object.assign({ layout: layout }, { children: _jsx(InputControl, Object.assign({ "data-test": id, error: errors[0] || '', inputMode: "text", isInvalid: isInvalid, isRequired: resolved.required, label: resolved.label }, { children: _jsxs(HStack, Object.assign({ alignItems: "flex-end", borderBottom: "2px solid", spacing: "6", sx: {
                    '&:focus-within': {
                        borderBottomColor: isInvalid ? 'red.100' : 'darkBrandBlue.40',
                    },
                    borderBottomColor: isInvalid ? 'red.100' : 'darkBrandBlue.100',
                } }, { children: [_jsx(Textarea, { autoCapitalize: "none", autoCorrect: "off", border: "none", boxShadow: "none !important", "data-test": answer.id, defaultValue: defaultValue, inputMode: keyboardTypeMap[keyboard_type], isRequired: resolved.required, minH: minHeight, onChange: handleChange, placeholder: resolved.placeholder, pt: "4", px: "1", ref: inputRef, resize: "none", rows: 1, spellCheck: "false" }), max_length && (_jsx(Text, Object.assign({ color: "darkBrandBlue.20", fontSize: "sm", pb: "2" }, { children: `${totalChar}/${max_length}` })))] })) })) })));
};
